"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const workbox_window_1 = require("workbox-window");
const { swPath } = window;
(async () => {
    if ('serviceWorker' in window.navigator && swPath) {
        const wb = new workbox_window_1.Workbox(swPath);
        const currentRegistration = await wb.register();
        wb.addEventListener('waiting', () => {
            wb.messageSkipWaiting();
        });
        window.navigator.serviceWorker.getRegistrations().then(async (registrations) => {
            var _a;
            for (const registration of registrations) {
                if (registration !== currentRegistration) {
                    await registration.unregister();
                    (_a = registration.active) === null || _a === void 0 ? void 0 : _a.postMessage({ type: 'RELOAD' });
                }
            }
        });
    }
})();
